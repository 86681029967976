<template>
  <div class="order-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Card'
}
</script>

<style lang="stylus">
.order-container
  margin 60px auto 0
  padding-top 60px
  width 1100px
  height 632px
  background #ffffff
  text-align center
  font-size 24px
  box-shadow 0px 4px 26px 3px rgba(204,213,218,0.23)
  border-radius 20px
</style>


