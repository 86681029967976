<template>
<Card>
  <div class="invoice-box">
    <div class="img-box">
      <img src="../../assets/images/pay.png" alt="支付成功">
    </div>
    <div class="tips-box">
      订单提交成功，请等待工作人员审核
    </div>
    <div class="btn-area">
      <Button class="btn-back" size="large" type="primary" @click="handleBackClick">返回控制台</Button>
      <Button class="btn-check-order" size="large" type="success" @click="handleCheckOrderClick">查看订单</Button>
    </div>
  </div>
</Card>
</template>

<script>
import Card from '@components/Card'

export default {
  name: 'invoice',
  components: {
    Card
  }, 
  data() {
    return {
      
    }
  },
  methods: {
    handleBackClick() {
      this.$router.push({
        name: "controlBooth"
      })
    },
    handleCheckOrderClick() {
      this.$router.push({
        name: "orderList"
      })
    },
  }
}
</script>

<style lang="stylus">
btn-style(color)
  background color
  border 1px solid color

.invoice-box
  margin 50px auto 0
  width 640px
  .img-box
    margin 0 auto 30px
    width 269px
    img
      display block
      width 100%
  .tips-box
    margin-bottom 100px
  .btn-area
    .btn-back
      margin-right 100px
      btn-style(#03C9FF)
    .btn-check-order
      btn-style(#1FDABB)

</style>


